import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { get, map, compact } from '../../lib/nodash';

import MultiImageContentModule from '../DynamicPage/MultiImageContentModule';
import { findImages } from '../../lib/findImage';

const EXCLUDED_FILENAMES = [
  '_single',
  '_hero',
  '_hover',
  '_grid',
  '_verishop',
  '_cart',
  '5gallon',
  'cutoutcart',
  'mock-up',
  'bucket',
  '_web',
];

const ProductImagesSlider = ({
  product,
  loop,
  fit,
  fill,
  imageMatcher,
  paginationType,
  sizes,
}) => {
  const images = (
    get('media', product)
      ? get('media', product).map((x) => x.image)
      : get('images', product) || []
  ).filter((x) => {
    const matchers = imageMatcher ? [] : EXCLUDED_FILENAMES;
    return (
      compact(
        matchers.map(
          (frag) => (x.originalSrc || x.src).toLowerCase().indexOf(frag) === -1
        )
      ).length === matchers.length
    );
  });
  const filteredImages = imageMatcher
    ? findImages(images, imageMatcher)
    : images;

  return (
    <MultiImageContentModule
      images={map((x) => ({ image: x, fill }), filteredImages || [])}
      loop={loop}
      fill={fill}
      displayAs="slider"
      paginationType={paginationType}
      navigation={true}
      navColor="#000"
      sizes={sizes}
      swiperParams={{
        loop,
        lazy: {
          loadPrevNext: true,
          checkInView: true,
          loadPrevNextAmount: filteredImages.length,
        },
        spaceBetween: 0,
        slidesPerView: 1,
      }}
      fit={fit}
      sticky={true}
    />
  );
};

ProductImagesSlider.propTypes = {
  product: PropTypes.object.isRequired,
  loop: PropTypes.bool,
  fit: PropTypes.oneOf(['cover', 'contain']),
  fill: PropTypes.bool,
  imageMatcher: PropTypes.string,
  hideThumbnails: PropTypes.bool,
  paginationType: PropTypes.oneOf(['thumbnail', 'bullet']),
  sizes: PropTypes.string,
};

export default memo(ProductImagesSlider);
