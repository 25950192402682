import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';

import ProductImagesSlider from '../Product/ProductImagesSlider';

const DynamicProductImagesSlider = ({ blok, ...rest }) => {
  const { product } = blok;
  return (
    <ProductImagesSlider
      {...storyblokEditable(blok)}
      {...blok}
      product={product?.item || rest.product}
      {...rest}
    />
  );
};

DynamicProductImagesSlider.propTypes = {
  blok: PropTypes.shape({
    product: PropTypes.object.isRequired,
    loop: PropTypes.bool,
    fit: PropTypes.oneOf(['cover', 'contain']),
    fill: PropTypes.bool,
    imageMatcher: PropTypes.string,
    hideThumbnails: PropTypes.bool,
  }),
};

export default DynamicProductImagesSlider;
